@use "_color" as *;


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color:#5A5A5B;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


.upload--icon{
    fill: #D2E3EF;
    margin-bottom: 1rem;
}


.container-main{
    height: 100vh;
   
}
.column-direction{
    display: flex;
    flex-direction: column;
}
.pointer{
    cursor: pointer;
}
.login-text-input{
    font-size: 1.3rem;
    color: #7f8084;
}
.login-text-title{
    font-size: 1.3rem;
    color: #ABACB0;
}
.login-text-header{
    font-size: 2.4rem;
    color: #000;
    font-weight: 600;
}
.f-password{
    color:$primary-color;
}
.auth-wrapper{
    height: 100vh;
    background:white;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.bg-white{
    background-color:white
}

.suucess-icon{
    transform:scale(1);
    height:120px
}

.success-t-s{
    font-size: 1.3rem;
    font-weight: 600;
}

.success-t-text{
    color: #5CC39C;
    font-size: 1.2rem;
    font-weight: 600;
}
.card-element{
    background-color: white;
    min-height: 200px;
    border-radius: 5px;
    width:400px;
    padding:2rem 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
}

.hr-dark{
    height: 1px;
    width: 70%;
    background-color: rgba(128, 128, 128, 0.282);
}

.pri-blue{
    background-color:$primary-color;
    color:white;
    font-size:1.2rem;
    height:30px;
    border-radius: 5px;
    margin: 3px;
    min-width:150px
}

.countdown{

    color:#16DB93;
    font-size:1.2rem;
    margin-top: .7rem;
    // padding-left: 1rem;
    height:30px;
    border-radius: 5px;
    min-width:150px
}






.active-blue{
    color: #0065FF;
    font-size: 1.2rem;
}
.r-turn-text2{
    color:red;
    font-size:1.3rem;
    font-weight: 600;
}
.r-turn-text{
    margin-top: 1em;
    font-size:1.3rem;
    font-weight: 600;
}
.r-turn-text1{
    margin-top: 0.5em;
    font-size:1.3rem;
    font-weight: 600;
}

.form-element{
    width: 100%;


    button{
        cursor: pointer;
    }
    
}

.input-container{
    width: 100%;
    display: flex;
    background-color: $input-background;
    border-radius: 5px;
    position: relative;

        input[type=password]{
            width:100%;
            outline: none;
            background-color: transparent;
            padding-right: 30px;

        }
        input[type=text]{
            width:100%;
            outline:none;
            background-color: transparent;
            padding-right: 30px;
        }
        span{
            display:flex;
            justify-content: center;
            align-items: center;
            // width:10%;
            cursor: pointer;
            position:absolute;
            background-color: #F4F4F4;
            right:20px;
            top: 50%;
            transform: translateY(-50%);


        }
}


.MuiInputBase-input {
    width: 100%;
    border: 0;
    height:30px;
    background-color: #fff;
    border: 2px solid #EAEAEA;
    display: block;
    padding: 6px 15px 7px;
    box-sizing: content-box;
    border-radius: 5px;
    letter-spacing: inherit;
    -webkit-tap-highlight-color: transparent;
    transition: all 500ms linear;
}

.desktop-hide{
    display: none;
}

.MuiInputBase-input:focus-within{
    border: 2px solid $primary-color;
    outline: none;
    transition: all 500ms linear;

}

.top-big-nav{
    background-color: $primary-color;
    min-height:250px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.header-container{
    width: 70%;
    height:100%
}

.card-container-white{
    width: 70%;
    background-color: white;
    // border: 2px solid $primary-color;
    min-height: 400px;
    position:absolute;
    top: 220px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: -3px 13px 93px -10px rgba(0, 0, 0, 0.118);
    -webkit-box-shadow: -3px 13px 93px -10px rgba(0,0,0,0.25);
    -moz-box-shadow: -3px 13px 93px -10px rgba(0, 0, 0, 0.108);
}
.align-center{
    align-items: center;
    display: flex;
}

.full-width{
    width: 100%;
}


.withdraw-btn{
    background-color:#F28D03;
    height: 30px;
    color: white;
    width: 100px;
    border-radius: 2px;
    font-size: 1.1rem;
}
.t-headr-name{
    color: white;
    font-size:1.3rem;

}
.t-headr-number{
    color: white;
    font-size:1.3rem;

}

.hr-divider{
    width: 100%;
    height:1px;
    background-color: white;
}

.logo-icon{
    width: 100px;
    height: 40px;
    fill: white;
}
.space-between{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.center{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.form-text-input{
    width: 100%;
    font-size: 20px;
    display: flex;
    justify-content: right;
    color: $primary-color;
    position: relative;
    top:-1em;left:-0.5em;
    border-radius: 5px; 
}
 .form-submit-button{
    width: 100%;
    padding:1.3em;
    border:none;
    margin-top: 0.6em;
    margin-bottom: 1em;
    background-color: $primary-color;
    border-radius: 5px;
    font-size: 20px;
    font-weight:600;
    color:white
 }
 .form-text{
     width: 100%;
     text-align: center;    
     font-size: 20px;
     font-weight: 600;   
     color: rgba(0, 0, 0, 0.55);
 }
 .form-head{
     padding-top: 0.5em;
     font-size: 25px;
     font-weight: 700;
     line-height: 1.8;
     color: rgba(0, 0, 0, 0.55);
 }

 .justify-center{
     justify-content: center;
 }

 .withdraw-inner{
     width:50%;
     min-height:100px;
     display: flex;
     flex-direction: column;
     align-items: center;
    //  background-color: #5CC39C;

 }


 .withdraw-amount-text-i{
     font-weight: 700;
     font-size: 1.2rem;
 }


 .widraw-input{
    width: 60%;
    border: 0;
    height:20px;
    background-color: #fff;
    border: 2px solid #EAEAEA;
    display: block;
    padding: 6px 15px 7px;
    box-sizing: content-box;
    font-size: 1.8rem;
    border-radius: 5px;
    letter-spacing: inherit;
    -webkit-tap-highlight-color: transparent;
    transition: all 500ms linear;
 }



.widraw-input:focus-within{
    border: 2px solid $primary-color;
    outline: none;
    transition: all 500ms linear;

}

.border-blue{
    border: 2px solid $primary-color;
    border-radius: 5px;
}



.card-header-text{
    color: black;
    font-size: 1.3rem;
    font-weight: 700;
    margin-left:2rem;
}

.home-card-container{
    justify-self: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width:100%;
    height:100%;
    justify-content: center;
}


.table-container{
    overflow-x: scroll;
    width: 100%;
    margin-bottom: 3rem;
}


.log-out-txt{
    color: white;
    font-size: 1.3rem;
    margin-left: 0.5rem;
}


.logout-div{
    display: flex;
}

.logout-icon{
    fill: white;
    color: white;
    font-size:1.3rem;
    margin-top: 4px;
}


.inner-div-content{
    width: 60%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.medium-bold{
    font-weight: 700;
}

.grey-color{
    font-size: 1.3rem;
    color:rgba(128, 128, 128, 0.427)
}

.margin-left{
    margin-left: 2rem;
}


table{
    width: 100%;
    overflow-x: scroll;
}

tr{
    height:40px;
    text-align: center;
}

thead{
    background-color:#F4F4F4;
    
   
    
    
}


tr:nth-child(even){
    background-color:#F4F4F4;
    
}

.table-header{
    display: flex;
    align-items: flex-start;
    padding-left: 1rem;
    width: 130px;

    p{
        font-size:1.3rem;
    }
}


.table-items{
    display: flex;
    align-items: flex-start;
    padding-left: 1rem;

    p{
        font-size: 1.3rem;
    }
}


.blue-btn{
    background-color: $primary-color;
    color: white;
    height: 40px;
    width: 150px;
    border-radius:5px
}


.white-logo-cont{
    display:flex;
    align-items:center;
    cursor: pointer;
}

.white-logo-text{
    font-size: 2rem;
    font-weight: 700;
    color: white;
    margin-left: 0.4rem;
    
}


.loader-container{
    width:100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left:0px;
    background-color: rgba(0, 0, 0, 0.311);
    z-index: 1200;
    display: grid;
    place-items: center;
}

.loader-card{
    width:150px;
    height: 150px;
    background-color: white;
    border-radius: 5px;
    display: grid;
    place-items: center;
}



.mb-0-5{
    margin-bottom: 0.5rem;
}
.mb-0-3{
    margin-bottom: 0.3rem;
}
.mb-0-7{
    margin-bottom: 0.7rem;
}
.mb-1{
    margin-bottom: 1rem;
}
.mb-1-5{
    margin-bottom: 1.5rem;
}
.mb-1-3{
    margin-bottom: 1.3rem;
}
.mb-2{
    margin-bottom: 2rem;
}
.mb-3{
    margin-bottom: 3rem;
}
.mb-4{
    margin-bottom: 4rem;
}
.mb-5{
    margin-bottom: 5rem;
}
.mb-9{
    margin-bottom: 5rem;
}
.mt-1{
    margin-top: 1rem;
}
.mt-1-5{
    margin-top: 1.5rem;
}
.mt-2{
    margin-top: 2rem;
}
.mt-3{
    margin-top: 3rem;
}
.mt-4{
    margin-top: 4rem;
}
.mt-5{
    margin-top: 5rem;
}

.loading-page{
    background-color: white;
    width:100%;
    height: 100vh;
    display: grid;
    place-items: center;
}

.side-popup{
    width: 70%;
    height: 60px;
    background-color: #FEEBE5 ;
    border: 2px solid #EFDAD7;
    border-radius: 5px;
    position:fixed;
    top: -100px;
    left: 50%;
    z-index: 200;
    transform: translateX(-50%);
    transition: all 300ms linear;
}
.side-popup-success{
    width: 70%;
    height: 60px;
    background-color: #e5fee6 ;
    border: 2px solid #d7efda;
    border-radius: 5px;
    position:fixed;
    top: -100px;
    left: 50%;
    z-index: 200;
    transform: translateX(-50%);
    transition: all 300ms linear;
}

.show-popup{
    top: 30px;
    transition: all 300ms linear;

}
#popUp-title{
    color:#C82302;
    font-weight: 600;
    font-size: 1.3rem;
}
#popUp-success-title{
    color:#05c802;
    font-weight: 600;
    font-size: 1.3rem;
}
#popUp-message{
    color:#C82302;
    font-weight: 500;
    font-size: 1.3rem;
}
#popUp-success-message{
    color:#05c802;
    font-weight: 500;
    font-size: 1.3rem;
}

.error-text{
    color:#C82302;
    margin-top: 0.5rem;
    font-size: 1.3rem;
}

.popup-error-icon{
    fill:#C82302;
    margin-left: 1rem;
    margin-right: 1rem;
}
.success-text{
    color:#05c802;
    margin-top: 0.5rem;
    font-size: 1.3rem;
}

.popup-success-icon{
    fill:#05c802;
    margin-left: 1rem;
    margin-right: 1rem;
}

.page-not-found-cont{
    background-color: #5CC39C;
}


.hide{
    display: none;
}