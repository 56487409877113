
@use "_color" as *;

   .card-element{
       width: 100%;
   }


@media (max-width:600px) {

   .card-element{
       width: 70%;
   }

   .mobile-hide{
       display: none;
   }

   .desktop-hide{
    display: block;
    }

   .top-big-nav{
    min-height:140px;
   }

   .mobile-card-amount{
       width: 80%;
       height: 100px;
       background-color:white;
       position: absolute;
       left: 50%;
       transform: translateX(-50%);
       bottom: -70px;
       border-radius: 5px;
       box-shadow: 0px 10px 14px 0px rgba(0, 0, 0, 0.148);
        -webkit-box-shadow: 0px 10px 14px 0px rgba(0, 0, 0, 0.128);
        -moz-box-shadow: 0px 10px 14px 0px rgba(0, 0, 0, 0.15);
       z-index: 1200;
       display: flex;
       padding-left: 1rem;
       justify-content: center;
       flex-direction: column;


       .text-moble-txt{
           font-size: 1rem;
           font-weight: 500;
       }
       .text-moble-txt2{
           font-size: 1.1rem;
           font-weight: 600;
       }


       
     

   }


   .space-betwee{
    flex-wrap: nowrap;
}

   

   .outline-btn{
    border: 2px solid $primary-color;
    background-color:white;
    color:$primary-color;
    align-items: center;
    justify-content:center;
    display: flex;

}

.full-width-m{
    width:100%
}


.suucess-icon{
    margin-bottom: 3rem;
    transform: scale(1.6);
}


.success-t-text{
    margin-top: 2rem;
    margin-bottom: 3rem;
}

.m-row{
    display: flex;
    background-color: aqua;
}




.withdraw-inner{
    width:100%;
    padding-left: 2rem;
    padding-right: 2rem;
    height:90vh;
    margin-top: 2rem;

    justify-content: space-between;
}
.inner-div-content{
    width: 90%;
}
.justify-space-between-m{
    justify-content: space-between;
}
.withdraw-amount-text-i{
    margin-bottom: 2rem;
}


.full-m-height{
    min-height: 80vh;

}

.flex-m{
    display: flex;
}


.m-margin{
    display: block;
}


.m-white-border{
    border-color:white;
}
   .card-container-white{
    //    background-color: aqua;
       width: 100%;
       position: relative;
       padding-left: 1rem;
       padding-right: 1rem;
       top: 0px;
       left: 50%;
       transform: translateX(-50%);
       box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.118);
       -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.25);
       -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.108);
   }

   .r-turn-text1{
       font-size: 1.3rem;
   }

   .bottom-tab{
    background-color: $primary-color;
    width: 100%;
    height:60px;
    position: absolute;
    bottom:0px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;


}


.b-t-nav-items{
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.b-nav-icon{
    fill: white;
}

.b-nav-title{
    color: white;
    font-size: 1.1rem;
}
//    .card-header-text{
//        display: none;
//    }


   .card-element{
    width: 100%;
}
}

@media (min-width:992px) {
   .card-element{
       width:400px;
   }
   .desktop-hide{
    display: none;
    }
}
